export const getDistanceBetweenCoords = (lat1: number, lon1: number, lat2: number, lon2: number) => {

    lat1 = degreesToRadians(lat1);
    lon1 = degreesToRadians(lon1);
    lat2 = degreesToRadians(lat2);
    lon2 = degreesToRadians(lon2);

    const EARTH_RATIO_KM = 6371;
    const EARTH_RATIO_METERS = EARTH_RATIO_KM * 1000;
    let longitudeDiff = (lon2 - lon1);
    let latitudeDiff = (lat2 - lat1);
    let a = Math.pow(Math.sin(latitudeDiff / 2.0), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(longitudeDiff / 2.0), 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return EARTH_RATIO_METERS * c;
};

const degreesToRadians = (degrees: number) => {
    return degrees * Math.PI / 180;
};