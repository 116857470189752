import { getDistanceBetweenCoords } from "@root/framework/tools/geolocation";
import { useEffect } from "react"

const Geolocator = () => {

    const successPosition = (position:any) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        const latitudeArea = -33.0064309;
        const longitudeArea = -71.5487597;
        const distance = getDistanceBetweenCoords(latitude, longitude, latitudeArea, longitudeArea);
        console.log("DISTANCE: ", distance);
    }

    const errorPosition = () => {
        console.log("Unable to retrieve your location");
    }

    useEffect(() => {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(successPosition, errorPosition);
        } else {
            console.error("Geolocation not supported");
        }
    }, []);
}

export default Geolocator